<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        layout="horizontal"
        :label-col="{span:6}"
        :wrapper-col="{span:12}"
      >
        <br/>
         <a-form-model-item label=" " :colon="false">
            <span style="color:#1890ff;">用户自行通过搜索等非他人推荐方式完成购买的，用户和其具备宣传关系的大使存在销售关系</span>
          </a-form-model-item>
          <a-form-model-item label="扫码有效时长(分钟)" prop="scanMinute">
            <a-input-number v-model="form.scanMinute" style="width:500px;" />
            <div style="color:#1890ff;font-size: 13px;">用户通过专属的二维码或者链接在{ {{this.form.scanMinute}} }分钟内完成购买的，二者存在销售关系。</div>
          </a-form-model-item>
          <a-form-model-item label="签到有效时长(分钟)" prop="signerMinute">
            <a-input-number v-model="form.signerMinute" style="width:500px;" />
            <div style="color:#1890ff;font-size: 13px;">大使在驿站签到后{ {{this.form.signerMinute}} }分钟内，和该大使存在宣传关系的用户，在该驿站内通过扫驿站或驿站内机柜码完成购买的，二者存在销售关系。</div>
          </a-form-model-item>
        <br/>
        <br/>
        <a-form-model-item label=" " :colon="false">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-model-item>
    </a-form-model>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'

export default {
  components: {
    Breadcrumb
  },
  data () {
    const validInteger = (rule, value, callback) => {
      if (value) {
        if (!Number(value)) {
          return callback(new Error('请输入数值'))
        } else {
          const re = /^[0-9]*[1-9][0-9]*$/
          const rsCheck = re.test(value)
          if (!rsCheck) {
            return callback(new Error('请输入正整数'))
          }
        }
      }
      callback()
    }
    return {
      visible: false,
      breadList: [
        {
          name: '销售关系设置',
          url: ''
        }
      ],
      form: {
        scanMinute: null,
        signerMinute: null
      },
      rules: {
        scanMinute: [
          { required: true, message: '请输入扫码有效时长(分钟)', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ],
        signerMinute: [
          { required: true, message: '请输入签到有效时长(分钟)', trigger: 'blur' },
          { validator: validInteger, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getSaleSigner()
  },
  computed: {
  },
  methods: {
    async getSaleSigner () {
      const res = await this.$store.dispatch('http', {
        api: 'getSaleSigner'
      })
      if (res) {
        this.form = {
          scanMinute: res.scanMinute,
          signerMinute: res.signerMinute
        }
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          await this.$store.dispatch('http', {
            api: 'saveSaleSigner',
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '保存成功~' })
          this.getSaleSigner()
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 30px;
  }
}
</style>
